export class PaginationModule {
  public sectionLoadMore(data): void {
    // Add teasers to dom.
    $.each(data.teasers, (index: number, value: any) => {
      let teaserCol = document.createElement("div");
      teaserCol.setAttribute('class', 'col d-md-flex m-c0');
      teaserCol.appendChild($(value)[0]);
      $('.row[data-section-type]:last').append(teaserCol);
    });
  }

  public sectionLoadMoreThemeSection(data): void {
    // Add teasers to dom.
    $.each(data.teasers, (index: number, value: any) => {
      let LastTeaser = $('[last-row]');
      let test = document.createElement("div");
      test.setAttribute('class', 'col d-md-flex m-c0');
      test.appendChild($(value)[0]);
      LastTeaser.append(test);
    });
  }

  public frontpageLoadMore(data): void {
    // Add wide banner
    if (data.bannerWide !== null && data.bannerWide !== undefined && data.bannerWide.length > 0) {
      $('.load-more-content').append(`<div class="col-12 mb-5 load-more-wide">${data.bannerWide}</div>`);
      $('.load-more-content').append(`<div class="col-12 mb-5"><div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-5"></div></div>`);
    }

    // Add teasers to dom.
    $.each(data.teasers, (index: number, value: any) => {
      let teaserCol = document.createElement("div");
      teaserCol.setAttribute('class', 'col d-md-flex m-c0');
      teaserCol.appendChild($(value)[0]);
      $('.load-more-content:last .row:last').append(teaserCol);
    });
  }

  /*
   * Add a new page to browser history.
   */
  public addPageToHistory(): void {
    let currentPage = 0;
    const queryString: string = window.location.search;
    const hash: string = window.location.hash;
    // get url and remove query string and hash, since they are not used to get segment
    const url: string = window.location.href.replace(queryString, '').replace(hash, '');
    const segments: string[] = url.split('/');

    if (segments[segments.length - 1] === '') {
      // if last segment is empty the remove it from the array, to prevent getting // in url.
      segments.pop();
    }

    if (window.location.href.indexOf('/page/') > 0) {
      // the url contains a page number, get current page.
      currentPage = +url.substring(url.lastIndexOf('/') + 1);
      // remove current page from url.
      segments.pop();
    } else {
      // the url don't have a page number, so page value is added to segments.
      segments.push("page");
    }

    // update current page number and add it to segments.
    currentPage += 1;
    segments.push((currentPage).toString());

    // covert segments to an url add push it to browser history.
    history.pushState("state", "title: " + 1, segments.join('/') + queryString + hash);
  }
}
