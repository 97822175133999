import { Toast } from 'bootstrap';

export class Notifications {
  /**
   * Adds a notification toast to the page.
   * @param title The text showen in the notification header.
   * @param message the text showen in the notification body.
   * @param id The element id, used to show/remove the notification.
   * @param delay How long the notification should be showen, default is 10000 ms.
   */
  public static addNotification(title: string, message: string, id: string, delay: number = 10000): void {
    const toastContainer = document.getElementById('toastPlacement');
    if (!toastContainer) {
      return;
    }

    // The toast message html
    let html = `
<div id="${id}" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay="${delay}">
    <div class="toast-header">
        <strong class="me-auto">${title}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
        ${message}
    </div>
</div>`;

    toastContainer.innerHTML += html;

    const toastElement = document.getElementById(id);
    if (toastElement) {
      const toast = new Toast(toastElement);
      toast.show();

      toastElement.addEventListener('hidden.bs.toast', () => {
        toastElement.remove();
      });
    }
  }
}
