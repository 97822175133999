import * as $ from 'jquery';
const Cookies = require('js-cookie');

export class SimpleOnBoarding {
  private static cookieName = 'njy-sob';

  static init(): void {
    this.show();
  }

  private static show() {
    const count = this.getCurrentCount();
    if (count < 3) {
      this.IncrementCount(1);
      $('.simple-onboarding .close').on('click', () => { this.close(); });
      $('.simple-onboarding').show("fast");
    }
  }

  private static close(): void {
    this.IncrementCount(3);

    $('.simple-onboarding').hide("fast");
  }

  private static IncrementCount(add: number) {
    let count: number = this.getCurrentCount();
    count += add;

    Cookies.set(this.cookieName, count.toString(), { expires: 365, path: '' });
  }

  private static getCurrentCount() {
    let count = 0;
    const currentValue = Cookies.get(this.cookieName);

    if (currentValue !== undefined) {
      count = parseInt(currentValue, 10);
    }

    return count;
  }
}
