import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class HTMLReelsSliderElement extends HTMLElement {
  connectedCallback() {
    const swiperElement: HTMLElement = this.querySelector('.swiper');
    const prevBtn: HTMLElement = this.querySelector('.swiper-button--prev');
    const nextBtn: HTMLElement = this.querySelector('.swiper-button--next');

    if (!swiperElement) {
      console.warn('Reels slider is invalid', this);
      this.remove();
    }

    new Swiper(swiperElement, {
      modules: [Navigation],
      spaceBetween: '16px',
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.4,
        },
        768: {
          slidesPerView: 2.4,
        },
        1024: {
          slidesPerView: 3.4,
        }
      },
    });
  }
}

$(function () {
  // Define the new element
  customElements.define('reels-slider', HTMLReelsSliderElement);
});
