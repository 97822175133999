import { TeamFilter } from "./teamFilter";

export class SeriesportNu {
  static init() {

    console.debug('Initializing SerieSport:nu components');

    TeamFilter.init();
  }
}
